import { Divider, Flex, HStack, Link, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useEffect } from "react";
import { Board } from "../../../../components/Board";
import { CompanySelectMaster } from "../../../../components/CompanySelect/companySelectMaster";
import { MainBoard } from "../../../../components/MainBoard";
import { getQuotas } from "../../../../hooks/useQuotas";
import { api } from "../../../../services/api";
import { Bill, BillCategory, Broker, Coordinator, Quota, QuotaSale, ReadyQuota, Supervisor } from "../../../../types";
import { useReadyQuota } from "../../../../hooks/useReadyQuota";
import { ControlledInput } from "../../../../components/Forms/Inputs/ControlledInput";

import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from "../../../../components/Forms/Inputs/Input";
import { SolidButton } from "../../../../components/Buttons/SolidButton";
import { formatBRDate } from "../../../../utils/Date/formatBRDate";
import { RemoveButton } from "../../../../components/Buttons/RemoveButton";
import moneyToBackend from "../../../../utils/moneyToBackend";
import { useWorkingCompany } from "../../../../hooks/useWorkingCompany";
import { useErrors } from "../../../../hooks/useErrors";
import { formatInputDate } from "../../../../utils/Date/formatInputDate";
import { useQuotaSale } from "../../../../hooks/useQuotaSale";
import { PaymentFilterData, usePayments } from "../../../../hooks/usePayments";
import { BillFilterData, useBills } from "../../../../hooks/useBills";
import { Select } from "../../../../components/Forms/Selects/Select";
import { CompanySelect } from "../../../../components/CompanySelect";

import { ReactComponent as TagIcon } from '../../../../assets/icons/Tag.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/Back Arrow.svg';
import { ReactSelect, SelectOption } from "../../../../components/Forms/ReactSelect";


interface QuotaSaleParams{
    quota: string;
    quotaSale: string;
}

interface QuotaSaleFormData{
    id: number;
    ready_quota: number;
    sale_date:string;
    company:number;
    value: string;
    seller: string;
    buyer: string;
    cpf_cnpj: string;
    profit?: number;
    tax?: string;
    coordinator_id?: number;
    coordinator_value?: string;
    supervisor_id?: number;
    supervisor_value?: string;
    broker_id?: number;
    broker_value?: string;
    description?: string;

    partner_profit?: number;
}

interface PaymentOfQuota{
    id?: number;
    title: string;
    value: string;
    observation?:string;
    expire:string;
    company?:number;
    category:number;
    quota_sales_id?: number;
}

export interface ResumedBill{
    id: number;
    title: string;
    value: number;
    paid: number;
    expire: string;
    category: number;
    cash_desk_category: number;
    company: number;
    source?: number;
    observation: string;
    status?: boolean;
    quota_sales_id?: number;
    created_at?: Date;
    updated_at?: Date;
}

const EditQuotaFormSchema = yup.object().shape({
    description: yup.string().nullable(),
    seller: yup.string().required("Quem vendeu a cota?"),
    buyer: yup.string().required("Quem foi o comprador?"),
    value: yup.string().required("Informe o valor da venda"),
    tax: yup.string().nullable(),
    cpf_cnpj: yup.string().required("Qual o cpf ou cnpj proprietário?"),

    partner_profit: yup.string().nullable(),

    coordinator: yup.string().nullable(),

    sale_date: yup.string().required("Selecione a data da venda"),
});

const PaymentsOfQuotaSchema = yup.object().shape({
    title: yup.string().required("Qual o título desse recebimento"),
    value: yup.string().required("Informe o valor da venda"),
    expire: yup.string().required("Selecione a data da venda"),
    category: yup.number().required("Selecione uma categoria"),
    osbervation: yup.string().nullable(),
});

export default function EditQuotaSale(){
    const workingCompany = useWorkingCompany();

    const history = useHistory();
    const toast = useToast();
    const { showErrors } = useErrors();

    const params = useParams<QuotaSaleParams>();

    const quotaQuery = useReadyQuota(params.quota);
    const quotaSaleQuery = useQuotaSale(params.quotaSale);

    //const quota:Quota = (quotaQuery?.data ? quotaQuery?.data : null);
    const quotaSale:QuotaSale = (quotaSaleQuery?.data ? quotaSaleQuery?.data : null);
    const quota:ReadyQuota|null = quotaSale.ready_quota;  //(quotaSale ? quotaSale.ready_quota : null);

    const { handleSubmit, register, formState, control, watch, setValue} = useForm<QuotaSaleFormData>({
        resolver: yupResolver(EditQuotaFormSchema),
        defaultValues: {
            value: quotaSale && quotaSale.value.toString().replace('.', ','),
            sale_date: quotaSale && quotaSale.sale_date,
            buyer: quotaSale && quotaSale.buyer,
            coordinator_id: quotaSale && quotaSale.coordinator_id,
            supervisor_id: quotaSale && quotaSale.supervisor_id,
        }
    });

    const paymentsForm = useForm<PaymentOfQuota>({
        resolver: yupResolver(PaymentsOfQuotaSchema),
        defaultValues: {
            value: (quotaSale && quotaSale.ready_quota.value) ? quotaSale.ready_quota.value.toString().replace('.', ',') : '',
        }
    });

    const [paymentsOfQuota, setPaymentOfQuota] = useState<PaymentOfQuota[]>([]);

    // if((quotaSale && paymentsOfQuota.length === 0) && quotaSale.bills.length > 0){
    //     let currentPayments:PaymentOfQuota[] = [];
        
    //     quotaSale.bills.map((bill:ResumedBill) => {
    //         currentPayments.push({id: bill.id,title: bill.title, value: bill.value.toString().replace('.', ','), category: bill.category, expire: bill.expire, observation: bill.observation, company: bill.company});
    //     });

    //     setPaymentOfQuota(currentPayments);
    // }

    async function handleAddPayment(payment : PaymentOfQuota){
        try{
            if(!workingCompany.company){
                toast({
                    title: "Ué",
                    description: `Seleciona uma empresa para trabalhar`,
                    status: "warning",
                    duration: 12000,
                    isClosable: true,
                });

                return;
            }

            payment.value = moneyToBackend(payment.value);
            payment.title = `[${quotaSale.ready_quota.group}-${quotaSale.ready_quota.quota}] ${payment.title}`;

            const expireDateToShow = payment.expire;
            payment.expire = formatInputDate(payment.expire);
            
            payment.company = workingCompany.company?.id;
            payment.quota_sales_id = quotaSale.id;

            //console.log(payment);

            const response = await api.post('/bills/store', payment);

            payment.id = response.data.id;
            payment.expire = expireDateToShow;

            setPaymentOfQuota([
                ...paymentsOfQuota,
                payment
            ]);
        }catch(error:any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    // async function handleRemovePayment(index: number){
    //     const updatedPaymentsOfQuota = [...paymentsOfQuota];

    //     try{
    //         const response = await api.delete(`/bills/destroy/${updatedPaymentsOfQuota[index].id}`);

    //         updatedPaymentsOfQuota.splice(index, 1);

    //         setPaymentOfQuota(updatedPaymentsOfQuota);
    //     }catch(error:any) {
    //         showErrors(error, toast);

    //         if(error.response.data.access){
    //             history.push('/');
    //         }
    //     }
    // }

    //const [paymentsOfQuota, setPaymentOfQuota] = useState<PaymentOfQuota[]>([]);

    useEffect(() => {
    //     setPaymentOfQuota([
    //         {
    //             title: "Pagamento à vista", 
    //             value: (quota && quota.value) ? quota.value.toFixed(2) : '0',
    //             expire: new Date().toISOString().split("T")[0],
    //             category: 1
    //         }
    //     ])
        if((quotaSale && paymentsOfQuota.length === 0) && quotaSale.bills.length > 0){
            let currentPayments:PaymentOfQuota[] = [];
            
            quotaSale.bills.map((bill:ResumedBill) => {
                currentPayments.push({id: bill.id,title: bill.title, value: bill.value.toString().replace('.', ','), category: bill.category, expire: bill.expire, observation: bill.observation, company: bill.company});
            });

            setPaymentOfQuota(currentPayments);
        }
    }, [])

    //console.log(new Date().toISOString().split("T")[0]);

    // function handleAddPayment(payment : PaymentOfQuota){
    //     payment.value = moneyToBackend(payment.value);
    //     payment.title = `[${quota.group}-${quota.quota}] ${payment.title}`;

    //     setPaymentOfQuota([
    //         ...paymentsOfQuota,
    //         payment
    //     ])
    // }

    function handleRemovePayment(index: number){
        const updatedPaymentsOfQuota = [...paymentsOfQuota];

        updatedPaymentsOfQuota.splice(index, 1);
        console.log(updatedPaymentsOfQuota);

        setPaymentOfQuota(updatedPaymentsOfQuota);
    }

    function includeAndFormatData(quotaSaleData: QuotaSaleFormData){
        quotaSaleData.coordinator_value = moneyToBackend(quotaSaleData.coordinator_value ? quotaSaleData.coordinator_value : '');
        quotaSaleData.supervisor_value = moneyToBackend(quotaSaleData.supervisor_value ? quotaSaleData.supervisor_value : '');
        quotaSaleData.broker_value = moneyToBackend(quotaSaleData.broker_value ? quotaSaleData.broker_value : '');

        quotaSaleData.value = moneyToBackend(quotaSaleData.value);

        //quotaSaleData.partner_profit = partnerProfit.toFixed(2);

        quotaSaleData.tax = ((quotaSaleData.tax != null && quotaSaleData.tax != "") ? moneyToBackend(quotaSaleData.tax) : '');

        //const partnerValue = (quotaSaleData.partner_value ? parseFloat(quotaSaleData.partner_value) : 0);

        //quotaSaleData.profit = parseFloat(quotaSaleData.value) - (quotaSale.ready_quota.cost ? quotaSale.ready_quota.cost : 0) - partnerValue;

        quotaSaleData.profit = profit; //- partnerValue;
        quotaSaleData.partner_profit = partnerProfit; //- partnerValue;

        quotaSaleData.sale_date = formatInputDate(quotaSaleData.sale_date);

        if(!workingCompany.company){
            return quotaSaleData;
        }
        
        quotaSaleData.company = workingCompany.company?.id;

        return quotaSaleData;
    }

    const handleEditQuotaSale = async (quotaSaleData : QuotaSaleFormData) => {
        try{
            console.log(quotaSaleData);

            if(!workingCompany.company){
                toast({
                    title: "Ué",
                    description: `Seleciona uma empresa para trabalhar`,
                    status: "warning",
                    duration: 12000,
                    isClosable: true,
                });

                return;
            }

            quotaSaleData = includeAndFormatData(quotaSaleData);
            quotaSaleData.ready_quota = quotaSale.ready_quota.id
            quotaSaleData.id = quotaSale.id

            let billsOfQuota = paymentsOfQuota.map((payment: PaymentOfQuota) => {
                payment.expire = formatInputDate(payment.expire);
                payment.company = workingCompany.company?.id;
                //payment.quota_sales_id = response.data.id;

                //await api.post('/bills/store', payment);
                return payment;
            })

            console.log(quotaSaleData);

            const response = await api.post(`/quota_sales/update/${quotaSale.id}`, {quotaSale:quotaSaleData, bills: billsOfQuota});

            toast({
                title: "Sucesso",
                description: `A venda da cota ${quotaSale.ready_quota.group}-${quotaSale.ready_quota.quota} foi alterada.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            //history.replace('/venda-contempladas');

        }catch(error:any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const [categories, setCategories] = useState<BillCategory[]>([]);

    const loadCategories = async () => {
        const { data } = await api.get('/bill_categories');

        setCategories(data);
    }

    const [coordinatorsOptions, setCoordinatorOptions] = useState<SelectOption[]>();

    const loadCoordinators = async () => {
        api.get('/coordinators').then(response => {
            setCoordinatorOptions(response.data.data.map((coordinator: Coordinator) => {
                return {
                    value: coordinator.id,
                    label: coordinator.name
                }
            }))
        });
    }

    const [supervisorsOptions, setSupervisorsOptions] = useState<SelectOption[]>();

    const loadSupervisors = async () => {
        api.get('/supervisors').then(response => {
            setSupervisorsOptions(response.data.data.map((supervisor: Supervisor) => {
                return {
                    value: supervisor.id,
                    label: supervisor.name
                }
            }))
        });
    }

    const [brokersOptions, setBrokersOptions] = useState<SelectOption[]>();

    const loadBrokers = async () => {
        api.get('/brokers').then(response => {
            setBrokersOptions(response.data.data.map((broker: Broker) => {
                return {
                    value: broker.id,
                    label: broker.name
                }
            }))
        });
    }

    useEffect(() => {
        loadCategories();
        loadCoordinators();
        loadSupervisors();
        loadBrokers();
    }, []);

    const [profit, setProfit] = useState<number>(quotaSale ? quotaSale.profit : 0);
    const [partnerProfit, setProfitProfit] = useState<number>(quotaSale ? quotaSale.partner_profit : 0);
    const [coordinatorValue, setCoordinatorValue] = useState<number>(quotaSale && quotaSale.coordinator_value ? quotaSale.coordinator_value : 0);
    const [supervisorValue, setSupervisorValue] = useState<number>((quotaSale && quotaSale.supervisor_value) ? quotaSale.supervisor_value : 0);

    const calculateCoordinatorCommission = (value:number) => {
        if(quota){
            const percentOfQuota = (value*100)/quota.credit;

            console.log(percentOfQuota);

            const commissionPercent = quota.segment === "Imóvel" ? (percentOfQuota > 34 ? 1 : percentOfQuota > 30 ? 0.75 : percentOfQuota > 28 ? 0.50 : 0.30) : (percentOfQuota > 40 ? 1 : percentOfQuota > 38 ? 0.75 : percentOfQuota > 36 ? 0.50 : 0.30)
            console.log(commissionPercent);

            setCoordinatorValue((commissionPercent/100)*quota.credit);
        }
    }

    const calculateSupervisorCommission = (value:number) => {
        if(quota){
            const percentOfQuota = (value*100)/quota.credit;

            const commissionPercent = quota.segment === "Imóvel" ? (percentOfQuota > 34 ? 0.50 : percentOfQuota > 30 ? 0.37 : percentOfQuota > 28 ? 0.25 : 0.15) : (percentOfQuota > 40 ? 0.50 : percentOfQuota > 38 ? 0.37 : percentOfQuota > 36 ? 0.25 : 0.15)
        
            setSupervisorValue((commissionPercent/100)*quota.credit);
        }
    }

    useEffect(() => {
        const value = watch('value') === "" ? "0" : watch('value');
        const brokerValue = watch('broker_value') === "" ? "0" : watch('broker_value');
        const supervisorValue = watch('supervisor_value') === "" ? "0" : watch('supervisor_value');
        const coordinatorValue = watch('coordinator_value') === "" ? "0" : watch('coordinator_value');
        const tax = watch('tax') === "" ? "0" : watch('tax');

        const parsedValue = parseFloat(moneyToBackend(value ? value : '0'));
        const parsedBrokerValue = parseFloat(moneyToBackend(brokerValue ? brokerValue : '0'));
        const parsedSupervisorValue = parseFloat(moneyToBackend(supervisorValue ? supervisorValue : '0'));
        const parsedCoordinatorValue = parseFloat(moneyToBackend(coordinatorValue ? coordinatorValue : '0'));
        const parsedTax = parseFloat(moneyToBackend(tax ? tax : '0'));

        //console.log(tax, parsedTax);
        calculateCoordinatorCommission(parsedValue);
        calculateSupervisorCommission(parsedValue);

        setProfit(parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax);
        setProfitProfit((parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax)*((quotaSale && quotaSale.ready_quota.partner_percent) ? quotaSale.ready_quota.partner_percent/100 : 0));
    }, [watch('value'), watch('broker_value'), watch('supervisor_value'), watch('coordinator_value'), , watch('tax')]);

    console.log(quotaSale);
    console.log(watch('coordinator_id'), watch('supervisor_id'));

    useEffect(() => {
        if(quotaSale){
            setValue('coordinator_id', quotaSale.coordinator_id);
            setValue('supervisor_id', quotaSale.supervisor_id);
        }
    }, [quotaSale])

    return(
        <MainBoard sidebar="quotas" header={ <CompanySelectMaster/>}>

            <Link href="/venda-contempladas" display="flex" flexDirection="row" mb="10">
                <BackArrow width="20px" stroke="#4e4b66" fill="none"/>
                <Text ml="4">Voltar para as vendas</Text>
            </Link>

            {
                quotaQuery.isLoading ? (
                    <Flex justify="center">
                        <Spinner/>
                    </Flex>
                ) : ( quotaQuery.isError ? (
                        <Flex justify="center" mt="4" mb="4">
                            <Text>Erro listar as contas a pagar</Text>
                        </Flex>
                    ) : (
                        quotaSale ? (
                            <>
                                <HStack justifyContent="space-between" align="baseline">
                                    <Text mb="6" fontWeight="bold" fontSize="lg">Vender a cota</Text>
            
                                    {
                                        quotaSale.ready_quota && <Text mb="6" fontWeight="bold" fontSize="lg">{quotaSale.ready_quota.group}-{quotaSale.ready_quota.quota}</Text>
                                    }
                                </HStack>
            
                                <Board mb="14">
                                    
                                    <HStack justifyContent="space-between">
            
                                        <Stack spacing="0">
                                            <Text fontSize="sm" color="gray.800">Grupo e cota:</Text>
                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{quotaSale.ready_quota.group}-{quotaSale.ready_quota.quota}</Text>
                                        </Stack>
            
                                        <Stack spacing="0">
                                            <Text fontSize="sm" color="gray.800">Crédito:</Text>
                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.credit)}</Text>
                                        </Stack> 
            
                                        <Stack spacing="0">
                                            <Text fontSize="sm" color="gray.800">% paga pelo crédito:</Text>
                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{quotaSale.ready_quota.paid_percent}</Text>
                                        </Stack> 
            
                                        <Stack spacing="0">
                                            <Text fontSize="sm" color="gray.800">Custo da empresa:</Text>
                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.cost ? quotaSale.ready_quota.cost : 0)}</Text>
                                        </Stack> 
            
                                        <Stack spacing="0">
                                            <Text fontSize="sm" color="gray.800">Custo total:</Text>
                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.total_cost ? quotaSale.ready_quota.total_cost : 0)}</Text>
                                        </Stack>                  
                                    </HStack>
                                </Board>

                                <HStack mb="7" justifyContent={"space-between"}>
                                    <Stack spacing="0">
                                        <Text fontSize="sm" color="gray.800">Faturamento:</Text>
                                        <Text fontSize="sm" color="gray.800" fontWeight="bold">{ profit ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(profit) : '--'}</Text>
                                    </Stack>

                                    <HStack spacing="6">
                                        <Stack spacing="0">
                                            <Text fontSize="sm" color="gray.800">Custo do parceiro:</Text>
                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.partner_cost ? quota.partner_cost : 0)}</Text>
                                        </Stack>

                                        <Stack spacing="0">
                                            <Text fontSize="sm" color="gray.800">Percentual do parceiro:</Text>
                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.partner_percent ? quota.partner_percent : 0)}</Text>
                                        </Stack>

                                        <Stack spacing="0">
                                            <Text fontSize="sm" color="gray.800">Faturamento do parceiro:</Text>
                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(partnerProfit ? partnerProfit : 0)}</Text>
                                        </Stack>
                                    </HStack>
                                </HStack>

                                <Divider mb="8"/>

                                <Stack id="quotaSale" as="form" spacing="6" mb="14" onSubmit={handleSubmit(handleEditQuotaSale)}>
                                    <HStack spacing="4" align="baseline">
                                        <ControlledInput control={control} value={quotaSale.value.toFixed(2).replace('.', ',')} mask="money" name="value" type="text" placeholder="Valor (Entrada)" variant="outline" error={formState.errors.value} focusBorderColor="blue.800"/>

                                        <ControlledInput control={control} value={quotaSale.sale_date} name="sale_date" type="date" placeholder="Data da venda" variant="outline" error={formState.errors.sale_date} focusBorderColor="blue.800"/>

                                        <ControlledInput control={control} value={quotaSale.buyer} name="buyer" type="text" placeholder="Comprador" variant="outline" error={formState.errors.buyer} focusBorderColor="blue.800"/>
                                        
                                    </HStack>

                                    <HStack spacing="4" align="baseline">
                                        <ControlledInput control={control} value={quotaSale.tax ? quotaSale.tax.toFixed(2).replace('.', ',') : ''} mask="money" name="tax" type="text" placeholder="Taxa" variant="outline" error={formState.errors.tax} focusBorderColor="blue.800"/>

                                        <ControlledInput control={control} value={quotaSale.cpf_cnpj} name="cpf_cnpj" type="text" placeholder="Comprador" variant="outline" error={formState.errors.cpf_cnpj} focusBorderColor="blue.800"/>

                                        <ControlledInput control={control} value={quotaSale.seller} name="seller" type="text" placeholder="Vendedor" variant="outline" error={formState.errors.seller} focusBorderColor="blue.800"/>
                                    </HStack>

                                    <HStack spacing="4" align="baseline">
                                        {
                                            coordinatorsOptions && (
                                                <ReactSelect options={coordinatorsOptions} value={quotaSale.coordinator_id ? quotaSale.coordinator_id : ""} placeholder="Coordenador" control={control} label="Contato" name="coordinator_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.coordinator_id}/>
                                            )
                                        }

                                        <ControlledInput control={control} value={coordinatorValue ? coordinatorValue.toFixed(2).replace('.', ',') : ''} mask="money" name="coordinator_value" type="text" placeholder="Comissão do coordenador" variant="outline" error={formState.errors.coordinator_value} focusBorderColor="blue.800"/>

                                        {/* <ControlledInput control={control} value={quotaSale.supervisor} name="supervisor" type="text" placeholder="Supervisor" variant="outline" error={formState.errors.supervisor} focusBorderColor="blue.800"/> */}

                                        {
                                            supervisorsOptions && (
                                                <ReactSelect options={supervisorsOptions} value={quotaSale.supervisor_id ? quotaSale.supervisor_id : ""} placeholder="Supervisor" control={control} label="Contato" name="supervisor_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.supervisor_id}/>
                                            )
                                        }

                                        <ControlledInput control={control} value={supervisorValue ? supervisorValue.toFixed(2).replace('.', ',') : ''} mask="money" name="supervisor_value" type="text" placeholder="Comissão do supervisor" variant="outline" error={formState.errors.supervisor_value} focusBorderColor="blue.800"/>
                                    </HStack>

                                    <HStack spacing="4" align="center">
                                        {
                                            brokersOptions && (
                                                <ReactSelect options={brokersOptions} placeholder="Corretor" control={control} label="Contato" name="supervisor_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.supervisor_id}/>
                                            )
                                        }

                                        {/* <Input register={register} name="broker_id" type="text" placeholder="Corretor" variant="outline"error={formState.errors.broker_id} focusBorderColor="blue.800"/> */}

                                        <Input register={register} name="broker_value" type="text" placeholder="Comissão corretor" mask="money" variant="outline" error={formState.errors.broker_value} focusBorderColor="blue.800"/>
                                    
                                        {/* <Input register={register} name="partner_value" type="text" placeholder="Comissão do Parceiro" variant="outline" mask="money" error={formState.errors.partner_value} focusBorderColor="blue.800"/> */}
                                    </HStack>

                                    <HStack spacing="4" align="baseline">
                                        {/* <ControlledInput control={control} value={quotaSale.partner_profit ? quotaSale.partner_profit.toString().replace('.', ',') : ''} name="partner_value" type="text" placeholder="Valor de lucro do parceiro" mask="money" variant="outline" error={formState.errors.partner_profit} focusBorderColor="blue.800"/> */}
                                    </HStack>

                                    <HStack spacing="4" align="baseline">
                                        <Input register={register} name="description" type="text" placeholder="Descrição" variant="outline" error={formState.errors.description} focusBorderColor="blue.800"/>
                                    </HStack>
                                </Stack>

                                <HStack justifyContent="space-between" align="baseline">
                                    <Text mb="6" fontWeight="bold" fontSize="lg">Pagamentos a receber</Text>
                                </HStack>

                                <Board background="transparent" border="1px solid" borderColor="gray.300" boxShadow="none">
                                    {
                                        paymentsOfQuota && paymentsOfQuota.map((paymentOfQuota:PaymentOfQuota, index: number) => {

                                            const paymentCategory:undefined|BillCategory = categories.filter(obj => obj.id === paymentOfQuota.category)[0];

                                            return (
                                                <Stack key={`${paymentOfQuota.expire}-${paymentOfQuota.value}`} borderBottom="1px solid" pb="4" borderColor="gray.300" mb="14" spacing="4">
                                                    <HStack spacing="12" justifyContent="space-between">
                                                        <Text fontWeight="bold">{formatBRDate(paymentOfQuota.expire)}</Text>

                                                        <Text>{paymentOfQuota.title}</Text>

                                                        <Text fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(parseFloat(paymentOfQuota.value))}</Text>

                                                        <Flex fontWeight="500" alignItems="center" color="gray.800" fontSize="sm">
                                                            <TagIcon stroke="#4e4b66" fill="none" width="17px"/>
                                                            <Text ml="2">{paymentCategory ? paymentCategory.name : ''}</Text>
                                                        </Flex>
                                                    
                                                        <RemoveButton alignSelf="right" onClick={() => handleRemovePayment(index)}/>
                                                    </HStack>

                                                    <Text fontWeight="regular" fontSize="sm">{paymentOfQuota.observation}</Text>
                                                </Stack>
                                            );
                                        })
                                    }

                                    {/* <Divider/> */}

                                    <Stack as="form" onSubmit={paymentsForm.handleSubmit(handleAddPayment)}>
                                        <Stack spacing="6">
                                            <Text>Adicionar recebimento</Text>

                                            <HStack>
                                                <Input register={paymentsForm.register} name="title" type="text" placeholder="Título" variant="outline" error={paymentsForm.formState.errors.title} focusBorderColor="blue.800"/>

                                                <Input register={paymentsForm.register} name="value" type="text" placeholder="Valor" variant="outline" mask="money" error={paymentsForm.formState.errors.value} focusBorderColor="blue.800"/>
                                                
                                                <Input register={paymentsForm.register} name="expire" type="date" placeholder="Data do pagamento" variant="outline" error={paymentsForm.formState.errors.expire} focusBorderColor="blue.800"/>
                                            </HStack>

                                            <HStack>
                                                <Select register={paymentsForm.register} h="45px" value="0" name="category" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" placeholder="Categoria" error={paymentsForm.formState.errors.category}>
                                                    {categories && categories.map((category:BillCategory) => {
                                                        return (
                                                            <option key={category.id} value={category.id}>{category.name}</option>
                                                        )
                                                    })}
                                                </Select>

                                                <Input register={paymentsForm.register} name="observation" type="text" placeholder="Observação" variant="outline" error={paymentsForm.formState.errors.observation} focusBorderColor="blue.800"/>

                                                <SolidButton mr="6" color="white" bg="blue.800" colorScheme="blue" type="submit" isLoading={formState.isSubmitting}>
                                                    Adicionar
                                                </SolidButton>
                                            </HStack>
                                        </Stack>
                                    </Stack>
                                </Board>
                            </>
                        ): (
                            <Text>Nenhuma cota encontrada.</Text>
                        )
                    )
                )
            }


            

            <SolidButton form="quotaSale" mt="14" mr="6" color="white" bg="green.500" colorScheme="green" type="submit" isLoading={formState.isSubmitting}>
                Atualizar Venda
            </SolidButton>
        </MainBoard>
    )
}